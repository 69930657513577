.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

figure {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripes-wraper {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  filter: drop-shadow(0 0 20px #F29);
  overflow: hidden;
  position: absolute;
}

.stripe {
  width: 100%;
  height: 15px;
  background-color: #FFF;
  animation: scroll 1s linear infinite;
}
@keyframes scroll {
  from {
    transform: translateY(-30px);
  }
}
.stripe + .stripe {
  margin-top: 15px;
}

.gradient-mask {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #92F 0%, #F29 100%);
  mix-blend-mode: multiply;
  position: absolute;
}

.palm-tree {
  height: 400px;
  z-index: 100000;
  translate: 10px 50px;
}

figcaption {
  color: #EEE;
  position: fixed;
  right: 20px;
  bottom: 10px;
}

a {
  color: #F29;
  text-decoration: none;
}